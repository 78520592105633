import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IUser, LOCAL_STORAGE_CONSTANTS, RoutesConstants } from '../core';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  _uiRoutes = new RoutesConstants();
  constructor(private _router: Router) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const USER_RAW = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.user);
    if (USER_RAW) {
      const USER: IUser = JSON.parse(USER_RAW);
      if (USER.lastName && USER.firstName) {
        return true;
      } else {
        this._router.navigate([this._uiRoutes.auth.signInPhone]);
        return false;
      }

    } else {
      this._router.navigate([this._uiRoutes.auth.signInPhone])
        .catch();
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {
  _uiRoutes = new RoutesConstants();
  constructor(private _router: Router) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!localStorage.getItem(LOCAL_STORAGE_CONSTANTS.user)) {
      return true;
    } else {
      this._router.navigate([this._uiRoutes.dashboard.home])
        .catch();
      return false;
    }
  }

}
