export class Translate {

    private _translations: {
        en?: any;
        ar?: any;
    } = {};

    constructor() {
        this._translations.en = {
            now: 'now',
            'seconds ago': (p: { seconds: any }) => `${p.seconds} seconds ago`,
            'a minute ago': 'a minute ago',
            'minutes ago': (p: { minutes: any }) => `${p.minutes} minutes ago`,
            'minute ago': (p: { minute: any }) => `${p.minute} minutes ago`,
            'an hour ago': 'an hour ago',
            'hours ago': (p: { hours: any }) => `${p.hours} hours ago`,
            'a day ago': 'a day ago',
            'days ago': (p: { days: any }) => `${p.days} days ago`,
            'a month ago': 'a month ago',
            'months ago': (p: { months: any }) => `${p.months} months ago`,
            'a year ago': 'a year ago',
            'years ago': (p: { years: any }) => `${p.years} years ago`
        };

        this._translations.ar = {
            now: 'الآن',
            'seconds ago': (p: { seconds: any }) => `قبل ${p.seconds} ثوان`,
            'a minute ago': 'قبل دقيقة',
            'minutes ago': (p: { minutes: any }) => `قبل ${p.minutes} دقائق `,
            'minute ago': (p: { minute: any }) => ` قبل ${p.minute} دقيقة `,
            'an hour ago': 'قبل ساعة',
            'hours ago': (p: { hours: any }) => `قبل ${p.hours} ساعات`,
            'a day ago': 'في الامس',
            'days ago': (p: { days: any }) => `قبل ${p.days} أيام`,
            'a month ago': 'قبل شهر',
            'months ago': (p: { months: any }) => `قبل ${p.months} شهر`,
            'a year ago': 'قبل سنة',
            'years ago': (p: { years: any }) => `قبل ${p.years} أعوام`
        };
    }

    translate(locale: string, messageKey: string, parameters?: any): string {
        let translationsInLocale!: { [x: string]: any };

        if (locale === 'ar') {
            translationsInLocale = this._translations.ar;
        }

        if (locale === 'en') {
            translationsInLocale = this._translations.en;
        }

        if (translationsInLocale) {
            const TRANSLATION = translationsInLocale[messageKey];

            if (TRANSLATION) {
                if (typeof TRANSLATION === 'function') {
                    return TRANSLATION(parameters);
                }
                return TRANSLATION;
            }
        }

        return messageKey;
    }

}
