import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './components/footer/footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { DesktopHeaderComponent } from './components/desktop-header/desktop-header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';

const LAYOUT_COMPONENTS = [
  FooterComponent,
  DesktopHeaderComponent
];

@NgModule({
  declarations: [
    ...LAYOUT_COMPONENTS
  ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        RouterModule,
        MatMenuModule,
        MatSelectModule,
        MatToolbarModule,
        TranslateModule,
        MatBadgeModule,
        FormsModule
    ],
  exports: [
    ...LAYOUT_COMPONENTS
  ]
})
export class LayoutsModule {
}
