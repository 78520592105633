import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Direction } from '@angular/cdk/bidi';
import { CHUNK_ARRAY } from '../../../core/utils/misc.utils';
import { TypesService } from '../../../core';


@Component({
  selector: 'wi-mobi-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() totalElements = 0;
  @Input() pageLimit = 0;
  @Input() pageme: any;
  @Input() direction: Direction = 'rtl';
  @Input() selectedPage = 1;

  @Output() goToPage: EventEmitter<number> = new EventEmitter<number>();

  pagesList: number[] = [];
  previewPagesList: number[] = [];

  inactiveNext = this.selectedPage === this.pagesList.length;
  inactivePrev = this.selectedPage === 1;

  chunks: number[][] = [];

  showNextBlocBtn = false;
  showPrevBlocBtn = false;

  constructor(
    private _typesService: TypesService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalElements || changes.selectedPage) {
      this._initAll();
    }
  }

  ngOnInit(): void {

    this._typesService.page.subscribe(() => {
    this.selectPage(1);
      this.selectedPage = 1;

    });
    this._initAll();
  }

  selectPage(value: number) {
    this.selectedPage = value;
    this.goToPage.emit(this.selectedPage);
    this._setPagesArray();
  }

  nextPage() {
    this.selectedPage = this.selectedPage === this.pagesList[this.pagesList.length - 1] ? this.selectedPage : this.selectedPage + 1;
    this.goToPage.emit(this.selectedPage);
    this._setPagesArray();
  }

  previousPage() {
    this.selectedPage = this.selectedPage === 1 ? this.selectedPage : this.selectedPage - 1;
    this.goToPage.emit(this.selectedPage);
    this._setPagesArray();
  }

  nextChunk() {
    for (let i = 0; i < this.chunks.length; i++) {
      const VALUE = this.chunks[i];
      if (VALUE.includes(this.selectedPage)) {
        if (i === this.chunks.length - 1) {
          return;
        } else {
          this.selectPage(this.chunks[i + 1][0]);
          return;
        }
      }
    }
  }

  prevChunk() {
    for (let i = 0; i < this.chunks.length; i++) {
      const VALUE = this.chunks[i];
      if (VALUE.includes(this.selectedPage)) {
        if (i === 0) {
          return;
        } else {
          this.selectPage(this.chunks[i - 1][0]);
          return;
        }
      }
    }
  }

  private _initAll() {
    this.pagesList = [1];

    if (this.totalElements > this.pageLimit) {
      for (let i = 1; i < this.totalElements / this.pageLimit; i++) {
        this.pagesList.push(i + 1);
      }
    }

    this.chunks = this.pagesList.length > 4 ? CHUNK_ARRAY(this.pagesList, 3) : [];
    this._setPagesArray();
  }

  private _setPagesArray() {
    this._setNavActivation();
    if (this.pagesList.length <= 4) {
      this.previewPagesList = this.pagesList;
      return;
    }

    for (const VALUE of this.chunks) {
      if (VALUE.includes(this.selectedPage)) {
        this.previewPagesList = VALUE;
        this.showNextBlocBtn = VALUE[VALUE.length - 1] !== this.pagesList[this.pagesList.length - 1];
        this.showPrevBlocBtn = VALUE[0] !== this.pagesList[0];
        return;
      }
    }
  }

  private _setNavActivation() {
    this.inactiveNext = this.selectedPage === this.pagesList.length;
    this.inactivePrev = this.selectedPage === 1;
  }
}
