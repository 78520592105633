import { Component, Input, OnInit } from '@angular/core';
import { IPost, IUser } from '../../../core';
import { BasePage } from '../../classes';

@Component({
  selector: 'wi-mobi-post-item-with-actions',
  templateUrl: './post-item-with-actions.component.html',
  styleUrls: ['./post-item-with-actions.component.scss']
})
export class PostItemWithActionsComponent extends BasePage implements OnInit {
  @Input() postItem!: IPost;
  @Input() isFavorite = false;
  @Input() user!: IUser;
  rating = 0;
  starCount = 5;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.rating = this.postItem.rate;
  }

  onRatingChanged(rating: any) {
    this.rating = rating;
  }

  postDetails() {
    this.router
      .navigate([this.uiRoutes.products.productDetails], { queryParams: { id: this.postItem.id } })
      .catch(reason => {
        console.error(reason);
      });
  }

  editPost() {
    this.router
    .navigate([this.uiRoutes.products.editProduct], { queryParams: { id: this.postItem.id } })
    .catch(reason => {
      console.error(reason);
    });
  }

  setBackgroundImage() {
    return this.postItem && this.postItem.photo && this.postItem.photo.length ? {
      'background-image': `url(${this.postItem.photo[0]})`
    } : {};
  }
}
