import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiConstants } from '../../../constants';
import { NetworkService } from '../../networkService';
import { IResponse } from '../../../interfaces';


@Injectable({
  providedIn: 'root'
})
export class PublicUserService {
  apis = new ApiConstants();

  constructor(private _networkService: NetworkService) {
  }

  getUserById(id: number): Observable<IResponse> {
    const POSTS_API = this.apis.publicUser(id);
    return this._networkService.get(POSTS_API.getUserById);
  }

  getDetailsUserById(id: number): Observable<IResponse> {
    const POSTS_API = this.apis.publicUser(id);
    return this._networkService.get(POSTS_API.getDetailsUserById);
  }

  getPostsUserById(id: number, page?: number): Observable<IResponse> {
    const POSTS_API = this.apis.publicUser(id);
    return this._networkService.get(POSTS_API.getPostsUserById, { page });
  }

  getCommentsUserById(id: number, page?: number): Observable<IResponse> {
    const POSTS_API = this.apis.publicUser(id);
    return this._networkService.get(POSTS_API.getCommentsUserById, { page });
  }

  addCommentsUserById(id: number, data: { text: string; rate: number }): Observable<IResponse> {
    const POSTS_API = this.apis.publicUser(id);
    return this._networkService.post(POSTS_API.getCommentsUserById, data);
  }

  followUserById(id: number): Observable<IResponse> {
    const POSTS_API = this.apis.publicUser(id);
    return this._networkService.post(POSTS_API.followUserById, {});
  }

  unfollowUserById(id: number): Observable<IResponse> {
    const POSTS_API = this.apis.publicUser(id);
    return this._networkService.delete(POSTS_API.followUserById);
  }
}
