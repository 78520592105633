import { IUser } from './../../interfaces/user.interface';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApiConstants, LOCAL_STORAGE_CONSTANTS } from '../../constants';
import { IResponse } from '../../interfaces';
import { NetworkService } from '../networkService';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apis = new ApiConstants();
  authApi = this.apis.auth;

  constructor(private _router: Router, private _networkService: NetworkService) {
  }

  getToken(): string | null {
    return localStorage.getItem(LOCAL_STORAGE_CONSTANTS.token);
  }

  getSavedUser(): IUser | null {
    const USER_RAW = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.user);
    if (USER_RAW) {
      const USER: IUser = JSON.parse(USER_RAW);
      if (USER.lastName && USER.firstName) {
        return USER;
      } else {
        this.clearStorage()
          .then(() => {
            location.reload();
          });
        return null;
      }
    } else {
      return null;
    }
  }

  logout(fcmtoken: string): Observable<IResponse> {
    return this._networkService.post(this.authApi.logout, { fcmtoken });
  }

  clearStorage() {
    return new Promise((resolve) => {
      localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.auth);
      localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.token);
      localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.user);

      this._router.navigate(['/']);
      resolve();
    });
  }

  refreshToken(): Observable<IResponse> {
    return this._networkService.post(this.authApi.refreshToken, {});
  }

  checkPhone(phone: number): Observable<IResponse> {
    return this._networkService.post(this.authApi.checkPhone, { phone });
  }

  verify(code: number, phone: number): Observable<IResponse> {
    return this._networkService.post(this.authApi.verifyPhone, { code: code.toString(), phone });
  }

  signUp(phone: number, firstName: string, lastName: string, email: string): Observable<IResponse> {
    return this._networkService.post(this.authApi.signUp, { phone, firstName, lastName, email });
  }
}
