import { Injectable } from '@angular/core';
import { ApiConstants } from '../../../constants';
import { NetworkService } from '../../networkService';

@Injectable({
  providedIn: 'root'
})
export class MeClaimsService {
  apis = new ApiConstants();

  constructor(private _networkService: NetworkService) {
  }

  get() {
    const API_TYPES = this.apis.meClaims();
    return this._networkService.get(API_TYPES.getClaims);
  }

  getById(id: number) {
    const API_TYPES = this.apis.meClaims(id);
    return this._networkService.get(API_TYPES.getClaimsById);
  }
}
