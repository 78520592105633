import { Injectable } from '@angular/core';
import { ApiConstants } from '../../constants';
import { NetworkService } from '../networkService';


@Injectable({
  providedIn: 'root'
})
export class AboutService {
  apis = new ApiConstants();
  aboutApi = this.apis.about;

  constructor(private _networkService: NetworkService) {
  }

  get() {
    return this._networkService.get(this.aboutApi.get);
  }
}
