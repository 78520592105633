import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { BasePage } from '../../../../shared';
import { AuthService, ICategory, ICity, IPostFilter, IType, MeMessagesService, TypesService, MeProfileService } from '../../../../core';
import { Event, NavigationEnd } from '@angular/router';
import { debounceTime, distinctUntilChanged, first, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

interface IFKCategory extends ICategory {
  types: IType[];
}

@Component({
  selector: 'wi-mobi-desktop-header',
  templateUrl: './desktop-header.component.html',
  styleUrls: ['./desktop-header.component.scss']
})
export class DesktopHeaderComponent extends BasePage implements OnInit, AfterViewChecked, OnDestroy {
  searchParams: Observable<IPostFilter> = this.route.queryParams;
  user_id = 0;
  isAuth = false;
  canNotShowHeaderDesktop = false;
  cities: ICity[] = [];
  fkCategories: IFKCategory[] = [];
  selectedCity: ICity = {
    id: 0,
    name: this._lang === 'en' ? 'All regions' : 'كل المناطق',
    deleted_at: null,
    created_at: null,
    updated_at: null,
  };
  messageCount = 0;

  keyword = '';

  inputUpdate = new Subject<string>();

  private _unsubscriber = new Subject<any>();
  private _routesDesk = [
    this.uiRoutes.auth.signInPhone,
    this.uiRoutes.auth.signUpInfo,
    this.uiRoutes.auth.signInCode,
  ];

  constructor(
    private _authService: AuthService,
    private _typesService: TypesService,
    private _meMessageService: MeMessagesService,
    private _meProfileService: MeProfileService
  ) {
    super();
  }

  ngOnInit(): void {
    this.showLoading.isLoading = true;
    this.isAuth = !!this._authService.getSavedUser();
    const SAVED_USER = this._authService.getSavedUser();
    if (this.isAuth && SAVED_USER) {
      this._meProfileService.get().subscribe(res => {
        this.user_id = res.data.id;
        this.getMessageCount();
      });

      this.messageCount = SAVED_USER.msg_not_readed;

    }
    this._typesService.getCities()
      .pipe(first())
      .subscribe((res) => {
        if (res.success) {
          this.cities = res.data;
          this.cities.unshift({
            id: 0,
            name: this._lang === 'en' ? 'All regions' : 'كل المناطق',
            deleted_at: null,
            created_at: null,
            updated_at: null,
          });
          this._typesService.getCategories()
            .pipe(first())
            .subscribe((result) => {
              const REQUESTS: Promise<any>[] = [];
              result.data.forEach((category: ICategory) => {
                REQUESTS.push(
                  new Promise(resolve => {
                    this._typesService.getTypes(category.id)
                      .pipe(first())
                      .subscribe((typesResult) => {
                        this.fkCategories.push({
                          ...category,
                          types: typesResult.data
                        });
                        resolve();
                      });
                  })
                );
              });
              Promise.all(REQUESTS)
                .then(() => {
                  this.fkCategories.unshift({
                    active: null,
                    created_at: null,
                    id: 0,
                    isActive: true,
                    name: this._lang === 'en' ? 'all' : 'الكل',
                    photo: 'cow_home_custom',
                    updated_at: null,
                    types: []
                  });
                  this.showLoading.isLoading = false;
                });
            });
          this.searchParams
            .pipe(takeUntil(this._unsubscriber))
            .subscribe((params) => {
              if (params.name) {
                this.keyword = params.name;
              }

              if (params.city_id) {
                this.cities.forEach((city) => {
                  if (params.city_id && +params.city_id === city.id) {
                    this.selectedCity = city;
                  }
                });
              } else {
                this.selectedCity = this.cities[0];
              }
            });
        }
      });

    this.inputUpdate.pipe(
      takeUntil(this._unsubscriber),
      debounceTime(1500),
      distinctUntilChanged())
      .subscribe(value => {
        this.updateSearch({ name: value, page: 1 });
      });
  }

  ngAfterViewChecked(): void {
    this.router
      .events
      .subscribe((value: Event) => {
        if (value instanceof NavigationEnd) {
          const IDX_Q = value.url.indexOf('?') >= 0 ? value.url.indexOf('?') : value.url.length;
          const TEST_URL = value.url.substr(0, IDX_Q);
          this.canNotShowHeaderDesktop = this._routesDesk.includes(TEST_URL);
        }
      });
    this.isAuth = !!this._authService.getSavedUser();
  }

  ngOnDestroy() {
    this._unsubscriber.next();
    this._unsubscriber.complete();
  }

  getMessageCount() {
    this._meMessageService
      .getCount(this.user_id)
      .subscribe((res) => {
        this.messageCount = res.data.count;
        this.getMessageCount();
      });
  }

  logout() {
    this._authService.clearStorage()
      .then(() => {
        location.reload();
      });
  }

  updateSearch(params: Partial<IPostFilter>) {
    this.router.navigate([this.uiRoutes.products.all], {
      queryParams: params,
      queryParamsHandling: 'merge'
    });
  }

  selectChanged(id: number) {
    this.updateSearch({ city_id: id });
  }

  selectCategory(id: number) {
    this._typesService.updatepage();
    this.updateSearch({ category_id: id, type_id: 0, page : 1 });
  }

  selectType(type_id: number, category_id: number) {
    this.updateSearch({ type_id, category_id, page: 1 });
  }
}
