import { Injectable } from '@angular/core';
import { ApiConstants } from '../../../constants';
import { NetworkService } from '../../networkService';


@Injectable({
  providedIn: 'root'
})
export class TermsService {
  apis = new ApiConstants();
  termsApi = this.apis.terms;

  constructor(private _networkService: NetworkService) {
  }

  get() {
    return this._networkService.get(this.termsApi.get);
  }
}
