import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShowLoadingService {

  private _isLoading = false;


  constructor() {
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  set isLoading(value: boolean) {
    this._isLoading = value;
  }
}
