import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { HTTP_LOADER_FACTORY, SharedModule } from '../shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutsModule } from './layouts/layouts.module';
import { RequestServiceInterceptor } from '../core/services/requestService';
import { DesktopGuard, MobileGuard } from '../guards/device.guard';
import { AuthGuard, NoAuthGuard } from '../guards/auth.guard';
import { MatSnackBarModule } from '@angular/material/snack-bar';


const APP_GUARDS = [
  MobileGuard,
  DesktopGuard,
  AuthGuard,
  NoAuthGuard
];


const APP_PROVIDERS = [
  ...APP_GUARDS,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestServiceInterceptor,
    multi: true
  }
];
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HTTP_LOADER_FACTORY,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    LayoutsModule
  ],
  providers: [APP_PROVIDERS],
  bootstrap: [AppComponent],
  exports: [
    SharedModule
  ]
})
export class AppModule { }
