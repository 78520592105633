import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as Hammer from 'hammerjs';
import { BasePage } from '../../classes';

@Component({
  selector: 'wi-mobi-gallery-modal',
  templateUrl: './gallery-modal.component.html',
  styleUrls: ['./gallery-modal.component.scss']
})
export class GalleryModalComponent extends BasePage implements OnInit, AfterViewInit {
  @ViewChild('pinchableImage') imgElement!: ElementRef;

  @Input() image = '';
  @Input() images: string[] = [];
  @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();

  selectedIndex = 0;

  constructor() {
    super();
  }

  rightSwitch() {
    this.selectedIndex += 1;
    this.image = this.images[this.selectedIndex];
  }

  leftSwitch() {
    this.selectedIndex -= 1;
    this.image = this.images[this.selectedIndex];
  }

  ngOnInit(): void {
    this.selectedIndex = this.images.indexOf(this.image);
  }

  ngAfterViewInit(): void {
    const ELEMENT = this.imgElement.nativeElement;
    const HAMMER = new Hammer(ELEMENT);
    HAMMER.get('pinch').set({ enable: true });

    let posX = 0;
    let posY = 0;
    let scale = 1;
    let last_scale = 1;
    let last_posX = 0;
    let last_posY = 0;
    let max_pos_x = 0;
    let max_pos_y = 0;
    let transform = '';

    HAMMER.on('doubletap pan pinch panend pinchend', (ev: any) => {
      if (ev.type === 'doubletap') {
        transform =
          'translate3d(0, 0, 0) ' +
          'scale3d(2, 2, 1) ';
        scale = 2;
        last_scale = 2;
        try {
          if (window.getComputedStyle(ELEMENT, null).getPropertyValue('-webkit-transform').toString() !== 'matrix(1, 0, 0, 1, 0, 0)') {
            transform =
              'translate3d(0, 0, 0) ' +
              'scale3d(1, 1, 1) ';
            scale = 1;
            last_scale = 1;
          }
        } catch (err) {
          console.error(err);
        }
        ELEMENT.style.webkitTransform = transform;
        transform = '';
      }

      //pan
      if (scale !== 1) {
        posX = last_posX + ev.deltaX;
        posY = last_posY + ev.deltaY;
        max_pos_x = Math.ceil((scale - 1) * ELEMENT.clientWidth / 2);
        max_pos_y = Math.ceil((scale - 1) * ELEMENT.clientHeight / 2);
        if (posX > max_pos_x) {
          posX = max_pos_x;
        }
        if (posX < -max_pos_x) {
          posX = -max_pos_x;
        }
        if (posY > max_pos_y) {
          posY = max_pos_y;
        }
        if (posY < -max_pos_y) {
          posY = -max_pos_y;
        }
      }

      //pinch
      if (ev.type === 'pinch') {
        scale = Math.max(.999, Math.min(last_scale * (ev.scale), 4));
      }
      if (ev.type === 'pinchend') {
        last_scale = scale;
      }

      //panend
      if (ev.type === 'panend') {
        last_posX = posX < max_pos_x ? posX : max_pos_x;
        last_posY = posY < max_pos_y ? posY : max_pos_y;
      }

      if (scale !== 1) {
        transform =
          'translate3d(' + posX + 'px,' + posY + 'px, 0) ' +
          'scale3d(' + scale + ', ' + scale + ', 1)';
      }

      if (transform) {
        ELEMENT.style.webkitTransform = transform;
      }
    });
  }

  closeGallery() {
    this.closeEvent.emit();
  }
}
