<mat-toolbar [class.hidden-cuz-of-route-changes]="canNotShowHeaderDesktop"
             class="desktop-header toolbar-header center-horizontally justify-align-center full-width"
             [dir]="transDir.mainDirection">
  <div class="main-views-container center-horizontally space-between">
    <button class="header-logo-btn" mat-button [routerLink]="uiRoutes.dashboard.home">
      <mat-icon svgIcon="logo_custom"></mat-icon>
    </button>
    <button mat-button [routerLink]="uiRoutes.dashboard.home">
      {{'ToolbarItems.main' | translate}}
    </button>
    <button mat-button [matMenuTriggerFor]="sectionsMenu">
      {{'Home.Main.Col1.section' | translate}}
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <button mat-button [matMenuTriggerFor]="sectionsMenuCities">
      {{selectedCity.name}}
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <span></span>
    <div class="header-input center-horizontally space-between">
      <input type="text"
             [(ngModel)]="keyword"
             (ngModelChange)="this.inputUpdate.next($event)"
             (keydown.enter)="keyword && keyword.length && updateSearch({ name: keyword, page: 1 })"
             [placeholder]="'Shared.Toolbar.search_here' | translate"
      >
      <button mat-icon-button
              (keydown.space)="updateSearch({ name: keyword, page: 1 })"
              [disabled]="!keyword || !keyword.length"
              (click)="updateSearch({ name: keyword, page: 1  })">
        <mat-icon>search</mat-icon>
      </button>
    </div>
    <button mat-button color="primary" [routerLink]="uiRoutes.users.myAccount">
      {{'Shared.Toolbar.my_account' | translate}}
    </button>
    <button *ngIf="isAuth" [matBadge]="messageCount ? messageCount : null" matBadgeColor="warn" mat-button color="primary" [routerLink]="uiRoutes.chat.conversationsList">
      {{'Home.Chat.title' | translate}}
    </button>
    <button *ngIf="!isAuth" class="join-btn" mat-flat-button color="primary" [routerLink]="uiRoutes.auth.signInPhone">
      {{'Shared.Toolbar.join_halal' | translate}}
    </button>
    <button *ngIf="isAuth" (click)="logout()" class="join-btn" mat-button color="primary">
      {{'Shared.Toolbar.Log_out' | translate}}
    </button>
  </div>
</mat-toolbar>

<mat-menu [dir]="transDir.mainDirection" #sectionsMenu="matMenu" [overlapTrigger]="false">
  <button
    mat-menu-item
    *ngIf="fkCategories.length"
    (click)="selectCategory(fkCategories[0].id)">
    {{fkCategories[0].name}}
  </button>
  <button mat-menu-item
          (click)="selectCategory(category.id)"
          [matMenuTriggerFor]="submenu"
          *ngFor="let category of fkCategories | slice:1"
          [matMenuTriggerData]="{types: category.types}">
    {{category.name}}
  </button>
</mat-menu>

<mat-menu #submenu="matMenu">
  <ng-template matMenuContent let-types="types">
    <button mat-menu-item (click)="selectType(type.id, type.category_id)"
            *ngFor="let type of types">{{type.name}}</button>
  </ng-template>
</mat-menu>

<mat-menu #sectionsMenuCities="matMenu">
  <button (click)="selectChanged(city.id)" mat-menu-item *ngFor="let city of cities" [value]="city.id">
    {{city.name}}
  </button>
</mat-menu>
