import { ChangeDetectorRef, NgZone, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Translate } from '../classes';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  private _timer: number | null = null;
  private _translate: Translate = new Translate();
  private _lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ar';

  constructor(private _changeDetectorRef: ChangeDetectorRef, private _ngZone: NgZone) {
  }

  transform(value: number) {
    const NEW_VALUE = value * 1000;
    this._removeTimer();
    const D = new Date(NEW_VALUE);
    const NOW = new Date();
    const SECONDS = Math.round(Math.abs((NOW.getTime() - D.getTime()) / 1000));
    const TIME_TO_UPDATE = this._getSecondsUntilUpdate(SECONDS) * 1000;
    this._timer = this._ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this._ngZone.run(() => this._changeDetectorRef.markForCheck());
        }, TIME_TO_UPDATE);
      }
      return null;
    });

    return this.getI18nMessage(SECONDS, this._lang || 'ar');
  }

  getI18nMessage(seconds: number, locale: string) {
    const MINUTES = Math.round(Math.abs(seconds / 60));
    const MINUTE = Math.round(Math.abs(seconds / 60));
    const HOURS = Math.round(Math.abs(MINUTES / 60));
    const DAYS = Math.round(Math.abs(HOURS / 24));
    const MONTHS = Math.round(Math.abs(DAYS / 30.416));
    const YEARS = Math.round(Math.abs(DAYS / 365));


    if (seconds <= 45) {
      return this._translate.translate(locale, 'now');
    } else if (seconds <= 90) {
      return this._translate.translate(locale, 'a minute ago');
    } else if (MINUTE >= 10 && MINUTES <= 44) {
      return this._translate.translate(locale, 'minute ago', { minute: MINUTE });
    } else if (MINUTES <= 45) {
      return this._translate.translate(locale, 'minutes ago', { minutes: MINUTES });
    } else if (MINUTES <= 90) {
      return this._translate.translate(locale, 'an hour ago');
    } else if (HOURS <= 22) {
      return this._translate.translate(locale, 'hours ago', { hours: HOURS });
    } else if (HOURS <= 36) {
      return this._translate.translate(locale, 'a day ago');
    } else if (DAYS <= 25) {
      return this._translate.translate(locale, 'days ago', { days: DAYS });
    } else if (DAYS <= 45) {
      return this._translate.translate(locale, 'a month ago');
    } else if (DAYS <= 345) {
      return this._translate.translate(locale, 'months ago', { months: MONTHS });
    } else if (DAYS <= 545) {
      return this._translate.translate(locale, 'a year ago');
    } else { // (days > 545)
      return this._translate.translate(locale, 'years ago', { years: YEARS });
    }
  }

  ngOnDestroy(): void {
    this._removeTimer();
  }

  private _removeTimer() {
    if (this._timer) {
      window.clearTimeout(this._timer);
      this._timer = null;
    }
  }

  private _getSecondsUntilUpdate(seconds: number) {
    const MIN = 60;
    const HR = MIN * 60;
    const DAY = HR * 24;
    if (seconds < MIN) { // less than 1 min, update ever 2 secs
      return 2;
    } else if (seconds < HR) { // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < DAY) { // less then a day, update every 5 mins
      return 300;
    } else { // update every hour
      return 3600;
    }
  }

}
