import { Component, Input, OnInit } from '@angular/core';
import { IUser } from '../../../core';

@Component({
  selector: 'wi-mobi-following-user-desktop',
  templateUrl: './following-user-desktop.component.html',
  styleUrls: ['./following-user-desktop.component.scss']
})
export class FollowingUserDesktopComponent implements OnInit {
  @Input() user!: IUser;
  starCount = 5;

  constructor() { }

  ngOnInit(): void {
  }


  setBackgroundImage() {
    return this.user && this.user.photo && this.user.photo.length ? {
      'background-image': `url(${this.user.photo})`
    } : {};
  }
}
