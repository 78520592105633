<div class="toolbar-mobile center-horizontally full-width"
     [class.hidden-cuz-of-route-changes]="canNotShowFooter">
  <div class="actions center-horizontally space-between full-width" [dir]="transDir.mainDirection">
    <div class="sides-buttons center-horizontally space-evenly full-width">
      <button mat-icon-button [routerLink]="uiRoutes.dashboard.home"
              [routerLinkActive]="'mat-primary'">
        <mat-icon svgIcon="home_custom"></mat-icon>
      </button>
      <button mat-icon-button [routerLink]="uiRoutes.products.myProducts"
              [routerLinkActive]="'mat-primary'">
        <mat-icon svgIcon="speaker_custom"></mat-icon>
      </button>
    </div>
    <button mat-fab
            color="primary" [routerLink]="uiRoutes.products.createProduct" class="center-fab">
      <mat-icon>add</mat-icon>
    </button>
    <div class="sides-buttons center-horizontally space-evenly full-width">
      <button [matBadge]="messageCount ? messageCount : null" matBadgeColor="warn" mat-icon-button [routerLink]="uiRoutes.chat.conversationsList"
              [routerLinkActive]="'mat-primary'">
        <mat-icon svgIcon="speak_custom"></mat-icon>
      </button>
      <button mat-icon-button [routerLink]="uiRoutes.dashboard.settingMenu"
              [routerLinkActive]="'mat-primary'">
        <mat-icon svgIcon="user_custom"></mat-icon>
      </button>
    </div>
  </div>
</div>

<div class="toolbar-desktop center-horizontally justify-align-center p-v-30"
     [class.hidden-cuz-of-route-changes]="canNotShowFooterDesk">
  <div class="center-vertically main-views-container" [dir]="transDir.mainDirection">
    <div class="center-horizontally space-between full-width">
      <mat-icon class="footer-logo" color="accent" svgIcon="logo_custom_neutral"></mat-icon>
      <div class="footer-filler">&nbsp;</div>
      <div class="footer-filler">&nbsp;</div>
      <div class="footer-filler">&nbsp;</div>
    </div>

    <div class="center-horizontally space-between full-width">
      <p class="footer-right-sec">{{'Shared.Footer.halal_description' | translate}}</p>
      <button [routerLink]="uiRoutes.dashboard.termsConditions"
              mat-button>{{'Shared.Footer.termsAndServices' | translate}}</button>
      <button [routerLink]="uiRoutes.dashboard.bankAccounts"
              mat-button>{{'Shared.Footer.bankList' | translate}}</button>
      
    </div>

    <div class="center-horizontally space-between full-width">
      <div class="footer-right-sec center-horizontally justify-align-left-horizontal flex-wrap">
        <a href="https://www.instagram.com/haalal_appp/" target="_blank">
          <mat-icon color="accent" svgIcon="ig_white_custom"></mat-icon>
        </a>

        <span></span>
        <a href="https://twitter.com/haalal_appp?s=08" target="_blank">
          <mat-icon color="accent" svgIcon="tw_white_custom"></mat-icon>
        </a>
        <span></span>
        <a href="https://www.snapchat.com/add/haalal_appp" target="_blank">
          <mat-icon color="accent" svgIcon="fb_white_custom"></mat-icon>
        </a>
      </div>
      <button [routerLink]="uiRoutes.dashboard.contactUs"
              mat-button>{{'Shared.Footer.contactUs' | translate}}</button>
      <button [routerLink]="uiRoutes.dashboard.reportList"
              *ngIf="isAuth"
              mat-button>{{'Shared.Footer.complaints' | translate}}</button>
      <button [routerLink]="uiRoutes.users.myAccount"
              *ngIf="isAuth"
              [queryParams]="{
              section: 'myFollowing'
              }"
              mat-button>{{'Shared.Footer.followings' | translate}}</button>


      <mat-select [value]="_lang ? _lang : 'ar'"
                  *ngIf="!isAuth"
                  (selectionChange)="changeLang($event.value)">
        <mat-option value="ar">{{ "ar" | translate }}</mat-option>
        <mat-option value="en">{{ "en" | translate }}</mat-option>
      </mat-select>
      <div class="footer-filler"
           *ngIf="!isAuth">&nbsp;</div>
    </div>

    <div class="center-horizontally space-between full-width"
         *ngIf="isAuth">
      <div class="footer-right-sec center-vertically justify-align-left-vertical">
        <h2>{{'Shared.Footer.halal_applications' | translate}}</h2>
        <div class="center-horizontally justify-align-left-horizontal">
          <button mat-button>
            <mat-icon svgIcon="app_store"></mat-icon>
          </button>
          <button mat-button>
            <mat-icon svgIcon="play_store"></mat-icon>
          </button>
        </div>
      </div>

      <button [routerLink]="uiRoutes.users.myAccount"
              [queryParams]="{
              section: 'myFavorites'
              }" mat-button>{{'Shared.Footer.Favorite' | translate}}</button>

      <button [routerLink]="uiRoutes.users.myAccount"
              [queryParams]="{
              section: 'myReviews'
              }" mat-button>{{'ToolbarItems.MyReviewsList' | translate}}</button>

      <mat-select [value]="_lang ? _lang : 'ar'"
                  (selectionChange)="changeLang($event.value)">
        <mat-option value="ar">{{ "ar" | translate }}</mat-option>
        <mat-option value="en">{{ "en" | translate }}</mat-option>
      </mat-select>

    </div>
    <br>
    <div class="center-horizontally full-width justify-align-center">
      <p>{{'Shared.Footer.all_rights_reserved' | translate: {year: year} }}</p>
    </div>
  </div>
</div>
