import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard, NoAuthGuard } from '../guards/auth.guard';

const APP_ROUTES: Routes = [
  {
    path: '',
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('../features/dashboard/dashboard.module').then(value => value.DashboardModule)
  },
  {
    path: 'products',
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('../features/products/products.module').then(value => value.ProductsModule)
  },
  {
    path: 'users',
    loadChildren: () => import('../features/users/users.module').then(value => value.UsersModule)
  },
  {
    path: 'chat',
    canActivate: [AuthGuard],
    loadChildren: () => import('../features/chat/chat.module').then(value => value.ChatModule)
  },
  {
    path: 'auth',
    canActivate: [NoAuthGuard],
    loadChildren: () => import('../features/auth/auth.module').then(value => value.AuthModule)
  }
];

@NgModule({

  imports: [RouterModule.forRoot(APP_ROUTES, {
    initialNavigation: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
