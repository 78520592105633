import { Injectable } from '@angular/core';
import { ApiConstants } from '../../../constants';
import { IDeletePostWithReasonBody, IPost } from '../../../interfaces';
import { NetworkService } from '../../networkService';

@Injectable({
  providedIn: 'root'
})
export class MePostsService {
  apis = new ApiConstants();

  constructor(private _networkService: NetworkService) {
  }

  get(page: number) {
    const API_TYPES = this.apis.mePosts();
    return this._networkService.get(API_TYPES.getUserPosts, { page });
  }

  createPost(data: IPost) {
    const API_TYPES = this.apis.mePosts();
    return this._networkService.post(API_TYPES.getUserPosts, data);
  }

  getPostById(id: number) {
    const API_TYPES = this.apis.mePosts(id);
    return this._networkService.get(API_TYPES.getUserPostById, {});
  }

  updatePostById(id: number, data: IPost) {
    const API_TYPES = this.apis.mePosts(id);
    return this._networkService.put(API_TYPES.getUserPostById, data);
  }

  deletePostWithReasonById(id: number, data: IDeletePostWithReasonBody) {
    const API_TYPES = this.apis.mePosts(id);
    return this._networkService.post(API_TYPES.deletePostWithReasonById, data);
  }

  getFavPosts(page: number) {
    const API_TYPES = this.apis.mePosts();
    return this._networkService.get(API_TYPES.getFavUserPost, { page });
  }

  addFavPost(id: number) {
    const API_TYPES = this.apis.mePosts(id);
    return this._networkService.post(API_TYPES.addFavPost, {});
  }

  removeFavPost(id: number) {
    const API_TYPES = this.apis.mePosts(id);
    return this._networkService.delete(API_TYPES.addFavPost);
  }
}
