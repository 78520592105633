import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class MobileGuard implements CanActivate {
  isMobile = this._breakpointObserver.isMatched('(max-width: 599px)') ||
    this._breakpointObserver.isMatched('(max-width: 959px)');

  constructor(private _breakpointObserver: BreakpointObserver, private _router: Router) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.isMobile) {
      return true;
    } else {
      this._router.navigate(['/'])
        .catch();

      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class DesktopGuard implements CanActivate {
  isMobile = this._breakpointObserver.isMatched('(max-width: 599px)') ||
    this._breakpointObserver.isMatched('(max-width: 959px)');

  constructor(private _breakpointObserver: BreakpointObserver, private _router: Router) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.isMobile) {
      this._router.navigate(['/'])
        .catch();

      return false;
    } else {
      return true;
    }
  }
}
