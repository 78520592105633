import { ServiceLocator } from './../shared/classes/service-locator.class';
import { AfterContentChecked, Component, Inject, Injector } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { OnInit } from '@angular/core/core';
import { TranslationAndDirectionService } from '../core/services/translation-and-direction.service';
import { AuthService, LOCAL_STORAGE_CONSTANTS, MeProfileService, ShowLoadingService, SVG_ICONS } from '../core';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'wi-mobi-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentChecked {
  title = 'halal-web-app';
  public isLoading = false;
  _lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ar';
  location = '';

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _translationService: TranslateService,
    private _injector: Injector,
    private _translationDirection: TranslationAndDirectionService,
    private _matIconRegistry: MatIconRegistry,
    private _showLoading: ShowLoadingService,
    private _authService: AuthService,
    private _meProfileService: MeProfileService,
    private _domSanitizer: DomSanitizer) {
    _translationService.setDefaultLang(this._lang ? this._lang : 'ar');
    this._document.documentElement.lang = this._lang ? this._lang : 'ar';
    this._document.documentElement.dir = this._lang === 'ar' ? 'rtl' : 'ltr';

    this._translationDirection.mainDirection = this._lang === 'en' ? 'ltr' : 'rtl';
    ServiceLocator.injector = this._injector;

    SVG_ICONS.forEach(value => {
      _matIconRegistry.addSvgIcon(
        value.name,
        _domSanitizer.bypassSecurityTrustResourceUrl(value.path)
      );
    });
  }

  ngOnInit() {
    if (this._authService.getToken()) {
      this._meProfileService
        .get()
        .subscribe((res) => {
          if (res.success) {
            localStorage.setItem(LOCAL_STORAGE_CONSTANTS.user, JSON.stringify(res.data));
          }
        });
    }
    this._translationService
      .onLangChange
      .subscribe((ev: LangChangeEvent) => {
        this._document.documentElement.lang = ev.lang;
        this._document.documentElement.dir = ev.lang === 'ar' ? 'rtl' : 'ltr';
        if (ev.lang === 'ar') {
          this._translationDirection
            .mainDirection = 'rtl';
        }

        if (ev.lang === 'en') {
          this._translationDirection
            .mainDirection = 'ltr';
        }
      });
  }

  ngAfterContentChecked(): void {
    this.isLoading = this._showLoading.isLoading;
  }

  getlocation() {
    return window.location.pathname;
  }

}
