import { Component, Input, OnInit } from '@angular/core';
import { IReview } from '../../../core';
import { BasePage } from '../../classes';

@Component({
  selector: 'wi-mobi-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent extends BasePage implements OnInit {
  @Input() comment!: IReview;
  @Input() showStars = false;
  @Input() userId = 0;

  starCount = 5;

  constructor() {
    super();
  }

  ngOnInit() {
  }
}
