import { Component, Input, OnInit } from '@angular/core';
import { BasePage } from '../../classes';
import { Location } from '@angular/common';


@Component({
  selector: 'wi-mobi-configurable-header',
  templateUrl: './configurable-header.component.html',
  styleUrls: ['./configurable-header.component.scss']
})
export class ConfigurableHeaderComponent extends BasePage implements OnInit {
  @Input() title = '';
  @Input() backPath = this.uiRoutes.dashboard.home;
  @Input() canGoBack = false;

  constructor(private _routerLocation: Location) {
    super();
  }

  ngOnInit(): void {
  }

  goBack() {
    if (document.referrer) {
      this._routerLocation.back();
    } else {
      this.router.navigate([this.backPath]);
    }
  }

}
