<div
  [dir]="transDir.mainDirection"
  class="comment-section center-horizontally justify-align-left-horizontal full-width m-b-40"
>
  <img
    *ngIf="comment.user.id === userId"
    matRipple
    [matTooltip]="comment.user.firstName"
    [src]="comment.user.photo ? comment.user.photo : 'assets/images/Avatar.png'"
    class="avatar-in-bg comment-section-avatar comment-section-avatar-circle"
  />
  <img
    *ngIf="comment.user.id !== userId"
    matRipple
    [routerLink]="uiRoutes.users.userDetails"
    [queryParams]="{
      id: comment.user.id
    }"
    [matTooltip]="comment.user.firstName"
    [src]="comment.user.photo ? comment.user.photo : 'assets/images/Avatar.png'"
    class="avatar-in-bg comment-section-avatar comment-section-avatar-circle"
  />
  <div
    class="comment-section-name-stars m-h-5 center-vertically justify-align-left-vertical full-width"
  >
    <div
      class="name-and-time center-horizontally full-width"
      [class.space-between]="isMobile"
      [class.justify-align-right-horizontal]="
        !isMobile && transDir.mainDirection === 'ltr'
      "
      [class.justify-align-left-horizontal]="
        !isMobile && transDir.mainDirection === 'rtl'
      "
    >
      <h1
      *ngIf="comment.user.id !== userId"
        class="name"
        [routerLink]="uiRoutes.users.userDetails"
        [queryParams]="{
          id: comment.user.id
        }"
      >
        {{ comment.user.firstName + " " + comment.user.lastName }}
      </h1>
      <h1
      *ngIf="comment.user.id == userId"
        class="name"
      >
        {{ comment.user.firstName + " " + comment.user.lastName }}
      </h1>
      <div class="name-dir-divider" *ngIf="!isMobile"></div>
      <span *ngIf="!isMobile" class="time">{{
        comment.created_at | timeAgo
      }}</span>
      <div
        *ngIf="isMobile"
        class="time center-horizontally justify-align-horizontally"
      >
        <mat-icon class="icon" svgIcon="clock_custom"></mat-icon>
        <span>{{ comment.created_at | timeAgo }}</span>
      </div>
    </div>
    <div *ngIf="showStars">
      <wi-mobi-stars-rating
        [rating]="comment.rate"
        [starCount]="starCount"
      ></wi-mobi-stars-rating>
    </div>
    <p class="comment">{{ comment.text }}</p>
  </div>
</div>
