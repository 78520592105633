<div [dir]="transDir.mainDirection" class="post-cancel-dialog justify-align-center">

  <p  class="title text-center">{{ i18nPrefix + "comfirmation.title" | translate }}</p>

  <div class="m-t-5 content center-horizontally justify-align-center space-evenly">
    <button
      type="submit"
      class="buttonYes"
      mat-flat-button
      [mat-dialog-close]="true"
      color="primary"
    >
      {{ i18nPrefix + "comfirmation.yes" | translate }}
    </button>
    <button
      type="submit"
      class="buttonNo "
      mat-flat-button
      color="red"
      (click)="close()"
    >
      {{ i18nPrefix + "comfirmation.no" | translate }}
    </button>
  </div>
</div>
