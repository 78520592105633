import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { Direction } from '@angular/cdk/bidi';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BasePage } from '../../classes';

@Component({
  selector: 'wi-mobi-add-comment-dialog',
  templateUrl: './add-comment-dialog.component.html',
  styleUrls: ['./add-comment-dialog.component.scss']
})
export class AddCommentDialogComponent extends BasePage implements OnInit {
  validateForm: FormGroup;
  i18nPrefix = 'Shared.Dialogs.AddComment.';
  dir: Direction = 'rtl';
  closeDir: Direction = this.dir === 'rtl' ? 'ltr' : 'rtl';
  starCount = 5;
  rate = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any = { isUserDetails: false },
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddCommentDialogComponent>
  ) {

    super();
    this.validateForm = this._formBuilder.group({
      comment: new FormControl('', [
        Validators.required
      ])
    });
  }

  submitForm() {
    if (!this.validateForm.valid) {
      return;
    }
    for (const I in this.validateForm.controls) {
      if (Object.prototype.isPrototypeOf.call(this.validateForm.controls, I)) {
        this.validateForm.controls[I].markAsDirty();
        this.validateForm.controls[I].updateValueAndValidity();
      }
    }

    return { text: this.validateForm.getRawValue().comment, rate: this.rate };
  }


  ngOnInit(): void {
    this.dir = this.transDir.mainDirection;
  }

  onRatingChanged(rating: any) {
    this.rate = rating;
  }

  close() {
    this.dialogRef.close();
  }
}
