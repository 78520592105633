import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BasePage } from '../../../shared';

@Component({
    selector: 'wi-mobi-logout-dialog',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutDialogComponent extends BasePage implements OnInit {
    i18nPrefix = 'Home.';

    constructor(public dialogRef: MatDialogRef<LogoutDialogComponent>) {
        super();
    }

    ngOnInit(): void {
    }

    close() {
        this.dialogRef.close();
    }

    submit() {
        this.close();
        localStorage.clear();
        this.router.navigate(['/'])
          .catch();
    }

}
