export interface IClaim {
  admin_note: string | null;
  created_at: number;
  deleted_at: number | null;
  id: 2;
  listPhotos: string[] | null;
  post_id: 13;
  status: 0;
  text: string;
  updated_at: number;
  user_id: number;
  filler?: boolean;
}

export const STATUS_TYPE = {
  0: 'new',
  1: 'still',
  2: 'ready'
};
