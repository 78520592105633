import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { NgModule } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCarouselModule } from '@ngbmodule/material-carousel';
import { RouterModule } from '@angular/router';

import { PostItemComponent } from './components/post-item/post-item.component';
import { ConfigurableHeaderComponent } from './components/configurable-header/configurable-header.component';
import { MaterialModule } from './material.module';
import { CommentComponent } from './components/comment/comment.component';
import { StarsRatingComponent } from './components/stars-rating/stars-rating.component';
import { PostItemWithActionsComponent } from './components/post-item-with-actions/post-item-with-actions.component';
import { AddCommentDialogComponent } from './dialogs/add-comment-dialog/add-comment-dialog.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { LogoutDialogComponent } from './dialogs/logout-dialog/logout.component';
import { GalleryModalComponent } from './components/gallery-modal/gallery-modal.component';
import { FollowingUserDesktopComponent } from './components/following-user-desktop/following-user-desktop.component';
import { ToastrModule } from 'ngx-toastr';
export const HTTP_LOADER_FACTORY = (httpClient: HttpClient) =>
  new TranslateHttpLoader(httpClient, '/assets/json/i18n/', '.json');

const SHARED_COMPONENTS = [
  PostItemComponent,
  ConfigurableHeaderComponent,
  StarsRatingComponent,
  CommentComponent,
  PostItemWithActionsComponent,
  PaginationComponent,
  GalleryModalComponent,
  FollowingUserDesktopComponent
];

const SHARED_DIALOGS = [
  AddCommentDialogComponent,
  LogoutDialogComponent
];

const SHARED_PIPES = [
  TimeAgoPipe
];

@NgModule({
  declarations: [
    ...SHARED_COMPONENTS,
    ...SHARED_DIALOGS,
    ...SHARED_PIPES
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HTTP_LOADER_FACTORY,
        deps: [HttpClient],
      }
    }),
    ReactiveFormsModule,
    MatCarouselModule.forRoot(),
    MaterialModule,
    ToastrModule.forRoot(),
    RouterModule
  ],
  exports: [
    HttpClientModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    MatCarouselModule,
    MaterialModule,
    ToastrModule,
    ...SHARED_COMPONENTS,
    ...SHARED_DIALOGS,
    ...SHARED_PIPES
  ]
})
export class SharedModule { }
