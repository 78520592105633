<div class="no-select pagination center-horizontally space-between" [dir]="direction">
  <span class="label">
    {{'Home.AllItems.pagination' | translate}}
  </span>
  <div class="pagination-buttons center-horizontally space-evenly" [dir]="direction">

    <span class="inactive-nav" *ngIf="inactivePrev">
      <mat-icon>{{direction === 'rtl' ? 'arrow_forward' : 'arrow_back'}}</mat-icon>
    </span>
    <span (click)="previousPage()" matRipple *ngIf="!inactivePrev">
      <mat-icon>{{direction === 'rtl' ? 'arrow_forward' : 'arrow_back'}}</mat-icon>
    </span>

    <span matRipple *ngIf="pagesList.length > 4 && showPrevBlocBtn" (click)="prevChunk()">...</span>

    <span matRipple
          [class.activated-btn]="selectedPage === n"
          (click)="selectPage(n)"
          *ngFor="let n of previewPagesList">
      {{n}}
    </span>

    <span matRipple *ngIf="pagesList.length > 4 && showNextBlocBtn" (click)="nextChunk()">...</span>

    <span class="inactive-nav" *ngIf="inactiveNext">
      <mat-icon>{{direction === 'rtl' ? 'arrow_back' : 'arrow_forward'}}</mat-icon>
    </span>
    <span (click)="nextPage()" matRipple *ngIf="!inactiveNext">
      <mat-icon>{{direction === 'rtl' ? 'arrow_back' : 'arrow_forward'}}</mat-icon>
    </span>

  </div>
</div>
