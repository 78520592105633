<div
  [dir]="transDir.mainDirection"
  [class.clickable]="clickable"
  [class.post-spec-width]="!clickable"
  [class.space-evenly]="clickable"
  [class.justify-align-left-horizontal]="!clickable"
  class="center-horizontally"
>
  <mat-icon
    [class.stars-for-post]="!clickable"
    [class]="setColor(i)"
    *ngFor="let ratingId of ratingArr; index as i"
    [id]="'star_' + i"
    (click)="onClick(i + 1)"
  >
    star
  </mat-icon>
</div>
