<div id="post-item-desktop"
     matRipple
     class="center-vertically justify-align-left-vertical m-b-30"
>
  <div class="product-img m-b-10" [ngStyle]="setBackgroundImage()">
  </div>
  <!--  <img [src]="postItem ? postItem.photo[0] : ''" alt="" class="m-b-10"/>-->
  <h2>{{ user.firstName ? user.firstName  : '' }}<span *ngIf="user.firstName">&nbsp;</span>{{ user.lastName ? user.lastName  : '' }}</h2>
  <wi-mobi-stars-rating
    [rating]="user && user.rate ? user.rate : 0"
    [starCount]="starCount"
  ></wi-mobi-stars-rating>
</div>
