import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BasePage } from '../../classes';

@Component({
  selector: 'wi-mobi-stars-rating',
  templateUrl: './stars-rating.component.html',
  styleUrls: ['./stars-rating.component.scss']
})
export class StarsRatingComponent extends BasePage implements OnInit {
  @Input() clickable = false;
  @Input() rating = 0;
  @Input() starCount = 5;
  @Output() ratingUpdated = new EventEmitter();
  showStars = false;

  ratingArr: number[] = [];

  constructor() {
    super();
  }


  ngOnInit() {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }

  onClick(rating: number) {
    if (this.clickable) {
      this.rating = rating;
      this.ratingUpdated.emit(rating);
    }
    return false;
  }

  setColor(index: number) {
    if (this.rating >= index + 1) {
      return 'active-star';
    } else {
      return 'inactive-star';
    }
  }

}
