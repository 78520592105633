import { Injectable } from '@angular/core';
import { NetworkService } from '../../networkService';
import { ApiConstants } from '../../../constants';
import { Observable } from 'rxjs';
import { IResponse } from '../../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  apis = new ApiConstants();

  constructor(private _nS: NetworkService) {
  }

  uploadVideo(file: File): Observable<IResponse> {
    const OBJ = { video: file };
    return this._nS.post(this.apis.uploader.video, this._nS.genFormData(OBJ));
  }

  uploadImage(file: File): Observable<IResponse> {
    const OBJ = { image: file };
    return this._nS.post(this.apis.uploader.image, this._nS.genFormData(OBJ));
  }
}
