<div
  id="post-item"
  class="center-horizontally justify-align-center"
  [dir]="transDir.mainDirection"
  (click)="postDetails()"
>
  <div
    *ngIf="postItem"
    class="item-content center-horizontally justify-align-left-horizontal full-height full-width"
  >
    <img
      [src]="postItem.photo.length ? postItem.photo[0] : ''"
      onerror="this.onerror=null;this.src='assets/images/placeholder_post.jpg';"
    />
    <span class="span-divider"></span>
    <div class="text-content center-vertically justify-align-left-vertical">
      <div class="center-horizontally full-width space-between">
        <span class="title">{{ postItem.name }}</span>
        <button
          *ngIf="isFavorite"
          class="fave-btn"
          mat-icon-button
          color="primary"
        >
          <mat-icon>favorite</mat-icon>
        </button>
        <button
          *ngIf="!isFavorite"
          class="edit-btn"
          (click)="editPost()"
          mat-button
          color="primary"
        >
          {{ "Home.AddAds.editAd" | translate }}
        </button>
      </div>

      <div class="center-horizontally full-width space-between">
        <div class="user-name">
          <span class="content-text">{{ postItem.category.name }}</span>
        </div>
        <span class="filling-line-post">&nbsp;</span>
      </div>
      <!--      <div class="center-horizontally full-width space-between">-->
      <!--        <span class="filling-line-post">&nbsp;</span>-->
      <!--        <span class="filling-line-post">&nbsp;</span>-->
      <!--      </div>-->

      <div class="center-horizontally full-width space-between">
        <div class="localisation">
          <mat-icon class="icon" svgIcon="geo_custom"></mat-icon>
          <span class="content-text">{{ postItem.city.name }}</span>
          <span class="content-text">{{ postItem.address ? " , " + postItem.address : "" }}</span>
        </div>
        <span class="filling-line-post">&nbsp;</span>
      </div>

      <div class="center-horizontally full-width space-between">
        <div class="user-name">
          <mat-icon class="icon" svgIcon="user_custom"></mat-icon>
          <span class="content-text">{{
            postItem.user.firstName + " " + postItem.user.lastName
          }}</span>
        </div>
        <div class="time">
          <mat-icon class="icon" svgIcon="clock_custom"></mat-icon>
          <span class="content-text">{{
            (isFavorite ? postItem.created_fav_at : postItem.created_at)
              | timeAgo
          }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<!--------------------------------------- Desktop -------------------------------------------->

<div
  id="post-item-desktop"
  matRipple
  class="center-vertically justify-align-left-vertical m-b-30"
>
  <div class="product-img m-b-10" [ngStyle]="setBackgroundImage()">
    <button *ngIf="isFavorite" class="fave-btn" mat-icon-button color="primary">
      <mat-icon>favorite</mat-icon>
    </button>
    <button
      [class.edit-product-eng]="transDir.mainDirection == 'rtl'"
      *ngIf="!isFavorite"
      class="edit-btn"
      (click)="editPost()"
      mat-flat-button
      color="primary"
    >
      {{ "Home.AddAds.editAd" | translate }}
    </button>
  </div>

  <h2>{{ postItem.name }}</h2>
  <span
    *ngIf="isFavorite"
    class="label-desktop center-horizontally justify-align-left-horizontal m-t-13"
  >
    <mat-icon class="label-icon" svgIcon="user_custom"></mat-icon>
    <span *ngIf="user" class="m-h-5">{{
      user.firstName + " " + user.lastName
    }}</span>
  </span>
  <span
    class="label-desktop center-horizontally justify-align-left-horizontal m-t-13"
  >
    <mat-icon class="label-icon" svgIcon="clock_custom"></mat-icon>
    <span class="m-h-5">{{ postItem.created_at | timeAgo }}</span>
  </span>
  <span
    class="label-desktop center-horizontally justify-align-left-horizontal m-t-13"
  >
    <mat-icon class="label-icon" svgIcon="geo_custom"></mat-icon>
    <span class="m-h-5">{{ postItem.city.name }}</span>
    <span class="content-text">{{ postItem.address ? " , " + postItem.address : "" }}</span>
  </span>
</div>
