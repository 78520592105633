import { environment } from '../../environments/environment.prod';

const API = environment.apiUri;
const AUTH = 'auth';
const ABOUT = 'about';
const TERMS = 'public/termsAndPolicy';
const PUBLIC_POSTS = 'public/posts';
const PUBLIC_TYPES = 'public/types';
const PUBLIC_USER = 'public/user';
const PUBLIC_PAYMENT = 'public/paymentMethods';
const PUBLIC_PERCENT = 'public/percent';
const ME_CLAIMS = 'me/claims';
const ME_POSTS = 'me/posts';
const ME_FOLLOW = 'me/following';
const ME_CONTACT = 'me/contact';
const ME_COMMENTS = 'me/comments';
const ME_MESSAGES = 'me/messages';
const ME_PROFILE = 'me';

export class ApiConstants {
  id: number | undefined;
  constructor() {
  }

  get auth() {
    return {
      logout: `${API}${AUTH}/logout`,
      refreshToken: `${API}${AUTH}/refresh`,
      checkPhone: `${API}${AUTH}/checkPhone`,
      verifyPhone: `${API}${AUTH}/verify`,
      signUp: `${API}${AUTH}/signup`,
    };
  }

  get uploader() {
    return {
      image: `${API}public/uploadImage`,
      video: `${API}public/uploadVideo`,
    };
  }

  get about() {
    return {
      get: `${API}${ABOUT}`
    };
  }

  get terms() {
    return {
      get: `${API}${TERMS}`
    };
  }

  publicPosts(id?: number) {
    return {
      getPostDetailsById: `${API}${PUBLIC_POSTS}/${id}/detaildatapost`,
      getHome: `${API}${PUBLIC_POSTS}/homeposts`,
      getCommentsPostById: `${API}${PUBLIC_POSTS}/${id}/comments`,
      getPosts: `${API}${PUBLIC_POSTS}`,
      addComment: `${API}${PUBLIC_POSTS}/${id}/comments`,
      addClaim: `${API}${PUBLIC_POSTS}/${id}/claim`
    };
  }

  publicTypes(id?: number) {
    return {
      getCategory: `${API}${PUBLIC_TYPES}/category`,
      getType: `${API}${PUBLIC_TYPES}/types/${id}`,
      getSecondaryType: `${API}${PUBLIC_TYPES}/typeSecondary/${id}`,
      getPosts: `${API}${PUBLIC_TYPES}`,
      getCities: `${API}${PUBLIC_TYPES}/cities`,
    };
  }

  publicUser(id?: number) {
    return {
      getUserById: `${API}${PUBLIC_USER}/${id}`,
      getDetailsUserById: `${API}${PUBLIC_USER}/${id}/detaildatauser`,
      getPostsUserById: `${API}${PUBLIC_USER}/${id}/posts`,
      getCommentsUserById: `${API}${PUBLIC_USER}/${id}/comments`,
      followUserById: `${API}${PUBLIC_USER}/${id}/follow`,
    };
  }

  get publicPayment() {
    return {
      get: `${API}${PUBLIC_PAYMENT}`
    };
  }

  get publicPercent() {
    return {
      get: `${API}${PUBLIC_PERCENT}`
    };
  }


  meClaims(id?: number) {
    return {
      getClaims: `${API}${ME_CLAIMS}`,
      getClaimsById: `${API}${ME_CLAIMS}/${id}`,
    };
  }

  mePosts(id?: number) {
    return {
      getUserPosts: `${API}${ME_POSTS}`,
      getUserPostById: `${API}${ME_POSTS}/${id} `,
      deletePostWithReasonById: `${API}${ME_POSTS}/${id}/reason `,
      getFavUserPost:  `${API}${ME_POSTS}/favorite`,
      addFavPost: `${API}${ME_POSTS}/${id}/favorite`,
    };
  }

  meFollow() {
    return {
      getFollows: `${API}${ME_FOLLOW}`
    };
  }

  meContact() {
    return {
      contactUs: `${API}${ME_CONTACT}`
    };
  }

  meComments() {
    return {
      get: `${API}${ME_COMMENTS}`
    };
  }

  meMessages(id?: number , user?: number) {
    return {
      get: `${API}${ME_MESSAGES}`,
      getCount: `${API}${ME_MESSAGES}/not_read_message_count/${id}`,
      refresh: `${API}${ME_MESSAGES}/refresh/${id}`,
      getRefresh: `${API}${ME_MESSAGES}/${id}/refresh/${user}`,
      byContactId: `${API}${ME_MESSAGES}/${id}`,
      getRefreshUp: `${API}${ME_MESSAGES}/${id}/refreshup/${user}`
    };
  }

  get meProfile() {
    return {
      me: `${API}${ME_PROFILE}`
    };
  }
}
