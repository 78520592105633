const PRODUCTS = '/products';
const AUTH = '/auth';
const DASH = '';
const USERS = '/users';
const CHAT = '/chat';

export class RoutesConstants {
  constructor() {
  }

  get dashboard() {
    return {
      home: `${DASH}/home`,
      bankAccounts: `${DASH}/bank-accounts`,
      bankAccountDetails: `${DASH}/bank-account-details`,
      contactUs: `${DASH}/contact-us`,
      report: `${DASH}/report`,
      reportList: `${DASH}/report-list`,
      settingMenu: `${DASH}/setting-menu`,
      termsConditions: `${DASH}/terms-conditions`
    };
  }

  get products() {
    return {
      myProducts: `${PRODUCTS}/my-products`,
      productDetails: `${PRODUCTS}/product-details`,
      favoriteProducts: `${PRODUCTS}/favorite-products`,
      editProduct: `${PRODUCTS}/edit-product`,
      all: `${PRODUCTS}/all`,
      createProduct: `${PRODUCTS}/create-product`
    };
  }

  get users() {
    return {
      editProfile: `${USERS}/edit-profile`,
      myFollowingList: `${USERS}/my-following-list`,
      myReviewsList: `${USERS}/my-reviews-list`,
      userDetails: `${USERS}/user-details`,
      myAccount: `${USERS}/my-account`
    };
  }

  get auth() {
    return {
      signInCode: `${AUTH}/sign-in-code`,
      signInPhone: `${AUTH}/sign-in-phone`,
      signUpInfo: `${AUTH}/sign-up-info`,
    };
  }

  get chat() {
    return {
      conversationsList: `${CHAT}/conversations-list`,
      conversation: `${CHAT}/conversation`
    };
  }
}
