<div [dir]="closeDir" class="add-comment-dialog">
  <button (click)="close()" class="close_button" mat-icon-button>
    <mat-icon class="close_icon">close</mat-icon>
  </button>
  <div [dir]="dir" class="content">
    <span *ngIf="data.data.isUserDetails" class="title">{{ i18nPrefix + "title" | translate }}</span>
    <span *ngIf="!data.data.isUserDetails" class="title">{{ "Home.ItemDetails.addComment" | translate }}</span>
    
    <form
      [formGroup]="validateForm"
      (ngSubmit)="submitForm()"
      class="add-comment-form full-height full-width"
      [dir]="dir"
    >

      <div *ngIf="data.data.isUserDetails">
        <wi-mobi-stars-rating
          [rating]="rate"
          [clickable]="true"
          [starCount]="starCount"
          (ratingUpdated)="onRatingChanged($event)"
        ></wi-mobi-stars-rating>
      </div>

      <mat-form-field
        [dir]="dir"
        appearance="fill"
        class="textarea-content center-vertically justify-align-center full-width"
      >
        <textarea
          matInput
          formControlName="comment"
          placeholder="{{ i18nPrefix + 'comment_placeholder' | translate }}"
        ></textarea>
      </mat-form-field>
      <button
        type="submit"
        class="send-button full-width"
        [mat-dialog-close]="submitForm()"
        [disabled]="!validateForm.valid"
        mat-flat-button
        color="primary"
      >
        {{ i18nPrefix + "send_btn" | translate }}
      </button>
    </form>
  </div>
</div>
