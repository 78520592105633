import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConstants } from '../../../constants';
import { IPostFilter, IResponse } from '../../../interfaces';
import { NetworkService } from '../../networkService';

@Injectable({
  providedIn: 'root'
})
export class PostsService {
  apis = new ApiConstants();

  constructor(private _networkService: NetworkService) {
  }

  getHome(): Observable<IResponse> {
    const POSTS_API = this.apis.publicPosts();
    return this._networkService.get(POSTS_API.getHome);
  }

  getPosts(params?: IPostFilter): Observable<IResponse> {
    const POSTS_API = this.apis.publicPosts();
    return this._networkService.get(POSTS_API.getPosts, params);
  }

  getPostsDetails(id: number): Observable<IResponse> {
    const POSTS_API = this.apis.publicPosts(id);
    return this._networkService.get(POSTS_API.getPostDetailsById);
  }

  getCommentsPost(id: number, page: number): Observable<IResponse> {
    const POSTS_API = this.apis.publicPosts(id);
    return this._networkService.get(POSTS_API.getCommentsPostById, { page });
  }

  addComment(id: number, params: { text: string; rate: number }): Observable<IResponse> {
    const POSTS_API = this.apis.publicPosts(id);
    return this._networkService.post(POSTS_API.addComment, params);
  }

  addClaim(id: number, params: { text: string; listPhotos: string[] }): Observable<IResponse> {
    const POSTS_API = this.apis.publicPosts(id);
    return this._networkService.post(POSTS_API.addClaim, params);
  }
}
