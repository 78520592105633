import { Observable, throwError } from 'rxjs';
import { AuthService } from './../authService/auth.service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RequestServiceInterceptor implements HttpInterceptor {

  constructor(
    private _authService: AuthService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Add authorization header with bearer token if available.
    const TOKEN = this._authService.getToken();
    const LANG = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ar';

    if (LANG) {
      request = request.clone({
        setHeaders: {
          'X-localization': LANG
        }
      });
    }

    if (TOKEN && request.url.includes('api')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${TOKEN}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 400:
          case 401:
            this._authService.clearStorage()
              .then(() => {
                location.reload();
              });
            return throwError(error);
          case 403:
            if (TOKEN) {
              this._authService.refreshToken()
                .pipe(first())
                .subscribe((res) => {
                  request = request.clone({
                    setHeaders: {
                      Authorization: `Bearer ${res.data}`
                    }
                  });
                  return next.handle(request);
                });
            }
            return throwError(error);
          default:
            console.error('default error = ', error);
            return throwError(error);
        }

      })
    );
  }
}
