import { Injectable } from '@angular/core';
import { ApiConstants } from '../../../constants';
import { NetworkService } from '../../networkService';

@Injectable({
  providedIn: 'root'
})
export class MeFollowService {
  apis = new ApiConstants();

  constructor(private _networkService: NetworkService) {
  }

  get(page: number) {
    const API_TYPES = this.apis.meFollow();
    return this._networkService.get(API_TYPES.getFollows, { page });
  }
}
