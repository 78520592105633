import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponse } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  constructor(private _http: HttpClient) {
  }

  private static _setQueryParams<T>(query: { [index: string]: any } | any | T): string {
    if (query) {
      const KEYS = Object.keys(query);
      if (KEYS.length > 0) {
        let queryParams = '?';
        KEYS.forEach((key) => {
          if (query[key]) {
            const S_PARAMETER = encodeURIComponent(query[key].toString().trim());
            queryParams += `${key}=${S_PARAMETER}&`;
          }
        });
        queryParams = queryParams.slice(0, -1);
        return queryParams;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  public genFormData(obj: {[index: string]: any}): FormData {
    const FORM = new FormData();
    const KEYS = Object.keys(obj);

    for (const KEY of KEYS) {
      FORM.set(KEY, obj[KEY]);
    }

    return FORM;
  }

  public post<T>(url: string, body: { [index: string]: any } | null | T, params?: HttpParams, headers?: HttpHeaders) {
    return this._http.post<IResponse>(url, body, { headers, params });
  }

  public postForm(url: string, body: { [index: string]: any } | null, headers?: HttpHeaders) {
    // let headers = new HttpHeaders();
    // headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this._http.post<IResponse>(url, body, { headers });
  }


  public get<T>(url: string, params?: { [index: string]: any } | null | T) {
    return this._http.get<IResponse>(`${url}${params ? NetworkService._setQueryParams<T>(params) : ''}`);
  }

  public put(url: string, body: FormData | { [index: string]: any } | null, params?: HttpParams, headers?: HttpHeaders) {
    return this._http.put<IResponse>(url, body, { headers, params });
  }

  public delete(url: string, params?: HttpParams, headers?: HttpHeaders) {
    return this._http.delete<IResponse>(url, { headers, params });
  }
}
