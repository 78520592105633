import { Injectable } from '@angular/core';
import { ApiConstants } from '../../../constants';
import { NetworkService } from '../../networkService';

@Injectable({
  providedIn: 'root'
})
export class MeMessagesService {
  apis = new ApiConstants();

  constructor(private _networkService: NetworkService) {
  }

  get() {
    const API_TYPES = this.apis.meMessages();
    return this._networkService.get(API_TYPES.get, {});
  }

  getCount(id: number) {
    const API_TYPES = this.apis.meMessages(id);
    return this._networkService.get(API_TYPES.getCount, {});
  }

  refresh(id: number) {
    const API_TYPES = this.apis.meMessages(id);
    return this._networkService.get(API_TYPES.refresh, {});
  }

  getRefresh(id: number, user: number) {
    const API_TYPES = this.apis.meMessages(id, user);
    return this._networkService.get(API_TYPES.getRefresh, {});
  }

  getByContactId(id: number) {
    const API_TYPES = this.apis.meMessages(id);
    return this._networkService.get(API_TYPES.byContactId, {});
  }

  getRefreshUp(id: number , user: number) {
    const API_TYPES = this.apis.meMessages(id , user);
    return this._networkService.get(API_TYPES.getRefreshUp, {});
  }

  sendMessage(id: number, text: string) {
    const API_TYPES = this.apis.meMessages(id);
    return this._networkService.post(API_TYPES.byContactId, { text });
  }
}
