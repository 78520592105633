import { ICustomSvgIcon } from '../interfaces';

export const SVG_ICONS: ICustomSvgIcon[] = [
  {
    name: 'app_store',
    path: '../assets/svg/app_store.svg'
  },
  {
    name: 'play_store',
    path: '../assets/svg/play_store.svg'
  },
  {
    name: 'tw_white_custom',
    path: '../assets/svg/tw_white.svg'
  },
  {
    name: 'li_white_custom',
    path: '../assets/svg/li_white.svg'
  },
  {
    name: 'ig_white_custom',
    path: '../assets/svg/ig_white.svg'
  },
  {
    name: 'fb_white_custom',
    path: '../assets/svg/fb_white.svg'
  },
  {
    name: 'yt_white_custom',
    path: '../assets/svg/yt_white.svg'
  },
  {
    name: 'logo_custom',
    path: '../assets/svg/app_logo.svg'
  },
  {
    name: 'logo_custom_neutral',
    path: '../assets/svg/app_logo_no_color.svg'
  },
  {
    name: 'ig_custom',
    path: '../assets/svg/ig.svg'
  },
  {
    name: 'fb_custom',
    path: '../assets/svg/fb.svg'
  },
  {
    name: 'wa_custom',
    path: '../assets/svg/wa.svg'
  },
  {
    name: 'tw_custom',
    path: '../assets/svg/tw.svg'
  },
  {
    name: 'share_custom',
    path: '../assets/svg/share.svg'
  },
  {
    name: 'nation_custom',
    path: '../assets/svg/nation.svg'
  },
  {
    name: 'grey_nation_custom',
    path: '../assets/svg/grey_nation.svg'
  },
  {
    name: 'cellphone_custom',
    path: '../assets/svg/cellphone.svg'
  },
  {
    name: 'speak_custom',
    path: '../assets/svg/speak.svg'
  },
  {
    name: 'speaker_custom',
    path: '../assets/svg/speaker.svg'
  },
  {
    name: 'home_custom',
    path: '../assets/svg/home-run.svg'
  },
  {
    name: 'mail_custom',
    path: '../assets/svg/mail.svg'
  },
  {
    name: 'user_custom',
    path: '../assets/svg/user.svg'
  },
  {
    name: 'chat_custom',
    path: '../assets/svg/chat.svg'
  },
  {
    name: 'clock_custom',
    path: '../assets/svg/clock.svg'
  },
  {
    name: 'like_custom',
    path: '../assets/svg/like.svg'
  },
  {
    name: 'phone_custom',
    path: '../assets/svg/phone.svg'
  },
  {
    name: 'camel_custom',
    path: '../assets/svg/camel.svg'
  },
  {
    name: 'white_camel_custom',
    path: '../assets/svg/camel_white.svg'
  },
  {
    name: 'white_horse_custom',
    path: '../assets/svg/white_horse.svg'
  },

  {
    name: 'white_goat_custom',
    path: '../assets/svg/white_goat.svg'
  },
  {
    name: 'white_sheep_custom',
    path: '../assets/svg/white_sheep.svg'
  },
  {
    name: 'white_cow_custom',
    path: '../assets/svg/white_cow.svg'
  },
  {
    name: 'cow_custom',
    path: '../assets/svg/cow.svg'
  },
  {
    name: 'other_custom',
    path: '../assets/svg/other.svg'
  },
  {
    name: 'white_other_custom',
    path: '../assets/svg/white_other.svg'
  },
  {
    name: 'green_other_custom',
    path: '../assets/svg/green_other.svg'
  },
  {
    name: 'cow_home_custom',
    path: '../assets/svg/cow_home.svg'
  },
  {
    name: 'white_cow_home_custom',
    path: '../assets/svg/white_cow_home.svg'
  },
  {
    name: 'goat_custom',
    path: '../assets/svg/goat.svg'
  },
  {
    name: 'geo_custom',
    path: '../assets/svg/geo.svg'
  },
  {
    name: 'heart_custom',
    path: '../assets/svg/heart.svg'
  },
  {
    name: 'horse_custom',
    path: '../assets/svg/horse.svg'
  },
  {
    name: 'sheep_custom',
    path: '../assets/svg/sheep.svg'
  },
  {
    name: 'ui_options_custom',
    path: '../assets/svg/ui_options.svg'
  },
  {
    name: 'setting_custom',
    path: '../assets/svg/setting.svg'
  },
  {
    name: 'saudia_flag_custom',
    path: '../assets/svg/Flag-Saudi-Arabia.svg'
  },
  {
    name: 'green_cow_custom',
    path: '../assets/svg/green_cow.svg'
  },
  {
    name: 'green_sheep_custom',
    path: '../assets/svg/green_sheep.svg'
  },
  {
    name: 'green_camel_custom',
    path: '../assets/svg/green_camel.svg'
  },
  {
    name: 'green_goat_custom',
    path: '../assets/svg/green_goat.svg'
  },
  {
    name: 'green_horse_custom',
    path: '../assets/svg/green_horse.svg'
  },
  {
    name: 'green_cow_home_custom',
    path: '../assets/svg/green_cow_home.svg'
  },
];
