import { Injectable } from '@angular/core';
import { ApiConstants } from '../../../constants';
import { IUser } from '../../../interfaces';
import { NetworkService } from '../../networkService';

@Injectable({
  providedIn: 'root'
})
export class MeProfileService {
  apis = new ApiConstants();
  profileApi= this.apis.meProfile;

  constructor(private _networkService: NetworkService) {
  }

  get() {
    return this._networkService.get(this.profileApi.me, {});
  }

  update(profile: IUser) {
    return this._networkService.post(this.profileApi.me, profile);
  }

}
