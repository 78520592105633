import { AuthService } from './../../core/services/authService/auth.service';
import { ComponentType } from '@angular/cdk/portal';
import { TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { IHTMLInputEvent, IResponse, RoutesConstants, ShowLoadingService, UploadService } from '../../core';
import { TranslationAndDirectionService } from '../../core/services/translation-and-direction.service';
import { ServiceLocator } from './service-locator.class';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

const IMAGE_TYPES: string[] = ['.jpg', '.jpeg', '.png'];
const VIDEO_TYPES: string[] = ['.mp4', '.webm', '.ogg', '.mov'];
const PLACEHOLDER_PATH = '../../../assets/images/cow-placeholder.jpg';
const MOBILE_QUERIES = '(max-width: 599px)';
const TABLET_QUERIES = '(max-width: 959px)';

export abstract class BasePage {

  public _lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ar';

  public catSubDivider = ' > ';
  public catLocationDivider = this._lang === 'ar' ? ' / ' : ' \\ ';

  private readonly _toasterService: ToastrService;

  private readonly _route: ActivatedRoute;

  private readonly _breakpointObserver: BreakpointObserver;

  private readonly _dialog: MatDialog;

  private readonly _router: Router;

  private readonly _showLoadingService: ShowLoadingService;

  private readonly _uploadService: UploadService;

  private readonly _translateService: TranslateService;

  private readonly _uiRoutes: RoutesConstants;

  private readonly _transDir: TranslationAndDirectionService;

  private readonly _baseAuthService: AuthService;

  private _imgTypes: string[] = IMAGE_TYPES;

  private _vidTypes: string[] = VIDEO_TYPES;

  private _selectedImages: { index: number; file: File }[] = [];

  private _selectedVideos: File[] = [];

  private _videosUrls: (string | ArrayBuffer)[] = [];

  private _imageUrls: (string | ArrayBuffer)[] = [];

  private _placeholderPath: string = PLACEHOLDER_PATH;

  private _acceptedTypes = this.imgTypes.join(',');

  private _screenUnsubscriber: Subject<BreakpointState> = new Subject<BreakpointState>();

  protected constructor() {
    this._toasterService = ServiceLocator.injector.get(ToastrService);
    this._route = ServiceLocator.injector.get(ActivatedRoute);
    this._breakpointObserver = ServiceLocator.injector.get(BreakpointObserver);
    this._router = ServiceLocator.injector.get(Router);
    this._translateService = ServiceLocator.injector.get(TranslateService);
    this._transDir = ServiceLocator.injector.get(TranslationAndDirectionService);
    this._dialog = ServiceLocator.injector.get(MatDialog);
    this._showLoadingService = ServiceLocator.injector.get(ShowLoadingService);
    this._baseAuthService = ServiceLocator.injector.get(AuthService);
    this._uploadService = ServiceLocator.injector.get(UploadService);
    this._uiRoutes = new RoutesConstants();
  }

  get screenUnsubscriber(): Subject<BreakpointState> {
    return this._screenUnsubscriber;
  }

  set screenUnsubscriber(value: Subject<BreakpointState>) {
    this._screenUnsubscriber = value;
  }

  get isMobile(): boolean {
    return this._breakpointObserver.isMatched(MOBILE_QUERIES) ||
      this._breakpointObserver.isMatched(TABLET_QUERIES);
  }

  set acceptedTypes(value: string) {
    this._acceptedTypes = value;
  }

  get acceptedTypes(): string {
    return this._acceptedTypes;
  }

  get selectedVideos(): File[] {
    return this._selectedVideos;
  }

  set selectedVideos(value: File[]) {
    this._selectedVideos = value;
  }

  get videosUrls(): (string | ArrayBuffer)[] {
    return this._videosUrls;
  }

  set videosUrls(value: (string | ArrayBuffer)[]) {
    this._videosUrls = value;
  }

  get imageUrls(): (string | ArrayBuffer)[] {
    return this._imageUrls;
  }

  set imageUrls(value: (string | ArrayBuffer)[]) {
    this._imageUrls = value;
  }

  get selectedImages(): { index: number; file: File }[] {
    return this._selectedImages;
  }

  set selectedImages(value: { index: number; file: File }[]) {
    this._selectedImages = value;
  }

  get imgTypes(): string[] {
    return this._imgTypes;
  }

  get vidTypes(): string[] {
    return this._vidTypes;
  }

  get uiRoutes(): RoutesConstants {
    return this._uiRoutes;
  }

  get route(): ActivatedRoute {
    return this._route;
  }

  get router(): Router {
    return this._router;
  }

  get translateService(): TranslateService {
    return this._translateService;
  }

  get transDir(): TranslationAndDirectionService {
    return this._transDir;
  }

  get showLoading(): ShowLoadingService {
    return this._showLoadingService;
  }

  set placeholderPath(value: string) {
    this._placeholderPath = value;
  }

  get placeholderPath(): string {
    return this._placeholderPath;
  }

  get uploadService(): UploadService {
    return this._uploadService;
  }

  isMobileObserver(): Observable<BreakpointState> {
    return this._breakpointObserver.observe([MOBILE_QUERIES, TABLET_QUERIES]);
  }

  async uploadAllFiles(type: 'images' | 'videos'): Promise<string[]> {
    let files_up: Promise<string>[] = [];

    if (type === 'images' && this.selectedImages && this.selectedImages.length) {
      files_up = this.selectedImages
        .map(async value => {
          const RESP: IResponse = await this.uploadService.uploadImage(value.file).toPromise();
          if (RESP.success) {
            return RESP.data.path;
          } else {
            return '';
          }
        });
    } else if (type === 'videos' && this.selectedVideos && this.selectedVideos.length) {
      files_up = this.selectedVideos
        .map(async value => {
          try {
            const RESP: IResponse = await this.uploadService.uploadVideo(value).toPromise();
            if (RESP.success) {
              return RESP.data.path;
            } else {
              return '';
            }
          } catch (e) {
            console.error(e);
            return '';
          }
        });
    }

    if (files_up) {
      try {
        const PATHS: string[] = await Promise.all(files_up);
        return Promise.resolve<string[]>(PATHS.filter(value => !!value.length));
      } catch (e) {
        console.error(e);
        return Promise.resolve<string[]>([]);
      }
    } else {
      return Promise.resolve<string[]>([]);
    }
  }

  openDialog<T>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    data: { [key: string]: any } = {}) {
    return this._dialog.open(componentOrTemplateRef, {
      data,
      width: '600px'
    }).afterClosed();
  }

  openDialogWithAuthCheck<T>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    data: { [key: string]: any } = {}) {
    const USER = this._baseAuthService.getSavedUser();

    if (USER) {
      return this._dialog.open(componentOrTemplateRef, {
        data,
        width: '600px'
      }).afterClosed();
    } else {
      this._router.navigate([this.uiRoutes.auth.signInPhone])
        .catch();
      return of(false);
    }
  }

  setButtonBg(img?: string | ArrayBuffer) {
    return {
      'background-image': `url("${img && true ? img : this.placeholderPath}")`
    };
  }

  readFile(file: File, placeholderPath: string): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>(resolve => {
      const READER = new FileReader();
      READER.readAsDataURL(file);

      READER.onload = () => {
        if (READER.result) {
          resolve(READER.result);
        } else {
          resolve(placeholderPath);
        }
      };
    });
  }

  isRTL(s: string) {
    const LTR_CHARS = 'A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF' + '\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF';
    const RTL_CHARS = '\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC';
    // eslint-disable-next-line no-misleading-character-class
    const RTL_DIR_CHECK = new RegExp('^[^' + LTR_CHARS + ']*[' + RTL_CHARS + ']');

    return RTL_DIR_CHECK.test(s);
  }

  removeFile(index: number, type: 'image' | 'video') {
    const IMMUT_URLS: (string | ArrayBuffer)[] = [...this.imageUrls];
    const IMMUT_IMAGES: { index: number; file: File }[] = [...this.selectedImages];
    const IMMUT_VID_URLS: (string | ArrayBuffer)[] = [...this.videosUrls];
    const IMMUT_VIDEOS: File[] = [...this.selectedVideos];

    if (type === 'image' && this.imageUrls[index]) {
      IMMUT_URLS.splice(index, 1);
      this.imageUrls = [...IMMUT_URLS];
      this.selectedImages = IMMUT_IMAGES.filter(element => element.index !== index);
    } if (this.videosUrls[index]) {
      IMMUT_VID_URLS.splice(index, 1);
      this.videosUrls = [...IMMUT_VID_URLS];

      if (this.selectedVideos[index]) {
        IMMUT_VIDEOS.splice(index, 1);
        this.selectedVideos = [...IMMUT_VIDEOS];
      }
    }
  }

  handleFileInput(e: IHTMLInputEvent | Event) {


    const TARGET = (e as IHTMLInputEvent).target;


    if (TARGET.files) {
      for (let i = 0; i < TARGET.files.length; i++) {
        if (TARGET.files.item(i)) {
          const F: File = TARGET.files.item(i) as File;

          if (this.acceptedTypes.includes(this.imgTypes[0])) {
            if (F.size > 20480000) {
              this.translateService.get('ToastMessages.file_size_limit', { size: 20480 })
                .pipe(first())
                .subscribe((val) => {
                  this.openToaster(val, 'error');
                });
            } else {
              this.readFile(F, this.placeholderPath)
                .then((value: string | ArrayBuffer) => {
                  this.imageUrls.push(value);
                  this.selectedImages.push({
                    index: this.imageUrls.length - 1,
                    file: F
                  });
                });
            }
          } else {
            if (F.size > 40960000) {
              this.translateService.get('ToastMessages.file_size_limit', { size: 40469 })
                .pipe(first())
                .subscribe((val) => {
                  this.openToaster(val, 'error');
                });
            } else {
              this.selectedVideos.push(F);
              this.readFile(F, PLACEHOLDER_PATH)
                .then((value: string | ArrayBuffer) => {
                  this.videosUrls.push(value);
                });

            }
          }
        } else {
          break;
        }
      }
    }
  }

  openToaster(message: string, type: 'error' | 'success' | 'info' | 'warning'): any {
    switch (type) {
      case 'error': {
        return this._toasterService.error(message);
      }
      case 'info': {
        return this._toasterService.info(message);
      }
      case 'success': {
        return this._toasterService.success(message);
      }
      case 'warning': {
        return this._toasterService.warning(message);
      }
      default: {
        return this._toasterService.info(message);
      }
    }
  }
}
