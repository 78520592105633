<div
  id="post-item"
  class="center-horizontally justify-align-center full-width"
  [dir]="transDir.mainDirection"
  mat-ripple
>
  <div class="item-content center-horizontally justify-align-left-horizontal">
    <img
      [src]="setImage()"
      alt=""
      onerror="this.onerror=null;this.src='assets/images/placeholder_post.jpg';"
    />
    <span class="span-divider"></span>
    <div
      class="
        text-content
        center-vertically
        justify-align-left-vertical
        full-width
      "
    >
      <div class="center-horizontally full-width space-between">
        <span class="title">{{ postItem.name }}</span>
      </div>

      <div class="center-horizontally full-width space-between">

        <div class="time">
          <mat-icon class="icon" svgIcon="clock_custom"></mat-icon>
          <span class="content-text">{{ postItem.updated_at | timeAgo }}</span>
        </div>
      </div>


      <div class="center-horizontally full-width space-between">
        <div class="user-name">
          <mat-icon class="icon" svgIcon="user_custom"></mat-icon>
          <span class="content-text">{{
            user.firstName + " " + user.lastName
          }}</span>
        </div>
        <div class="localisation">
          <mat-icon class="icon" svgIcon="geo_custom"></mat-icon>
          <span class="content-text"
            >{{ postItem.city.name }} 
            {{  postItem.address ? ' , ' + postItem.address : "" }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>

<div
  id="post-item-desktop"
  matRipple
  class="center-vertically justify-align-left-vertical m-b-30"
>
  <img
    [src]="setImage()"
    alt=""
    class="m-b-10"
    onerror="this.onerror=null;this.src='assets/images/placeholder_post.jpg';"
  />
  <h2>{{ postItem.name }}</h2>
  <!-- <wi-mobi-stars-rating
    [rating]="postItem.rate"
    [starCount]="starCount"
  ></wi-mobi-stars-rating> -->
  <span
    class="
      label-desktop
      center-horizontally
      justify-align-left-horizontal
      m-t-13
    "
  >
    <mat-icon class="label-icon" svgIcon="user_custom"></mat-icon>
    <span class="m-h-5">{{ user.firstName + " " + user.lastName }}</span>
  </span>
  <span
    class="
      label-desktop
      center-horizontally
      justify-align-left-horizontal
      m-t-13
    "
  >
    <mat-icon class="label-icon" svgIcon="clock_custom"></mat-icon>
    <span class="m-h-5">{{ postItem.updated_at | timeAgo }}</span>
  </span>
  <span
    class="
      label-desktop
      center-horizontally
      justify-align-left-horizontal
      m-t-13
    "
  >
    <mat-icon class="label-icon" svgIcon="geo_custom"></mat-icon>
    <span class="m-h-5">{{ postItem.city.name }}  {{  postItem.address ? ' , ' + postItem.address : "" }}</span>
  </span>
</div>
