<wi-mobi-desktop-header *ngIf="getlocation() !=='/download-app'"></wi-mobi-desktop-header>
<router-outlet></router-outlet>

<wi-mobi-footer *ngIf="getlocation() !=='/download-app'"></wi-mobi-footer>

<div #container class="loadingOverlay" *ngIf="isLoading">
  <div class="loadingCenter">
    <mat-progress-spinner [diameter]="container.offsetWidth > 599 ? 100 : 55" mode="indeterminate" color="primary">
    </mat-progress-spinner>
  </div>
</div>