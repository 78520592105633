import { Injectable, Output, EventEmitter } from '@angular/core';
import { ApiConstants } from '../../../constants';
import { NetworkService } from '../../networkService';

@Injectable({
  providedIn: 'root'
})
export class TypesService {
  @Output() page = new EventEmitter<any>();

  apis = new ApiConstants();

  constructor(private _networkService: NetworkService) {
  }

  getCities() {
    const API_TYPES = this.apis.publicTypes();
    return this._networkService.get(API_TYPES.getCities);
  }

  getCategories() {
    const API_TYPES = this.apis.publicTypes();
    return this._networkService.get(API_TYPES.getCategory);
  }

  getTypes(category_id: number) {
    const API_TYPES = this.apis.publicTypes(category_id);
    return this._networkService.get(API_TYPES.getType);
  }

  getSecondaryTypes(type_id: number) {
    const API_TYPES = this.apis.publicTypes(type_id);
    return this._networkService.get(API_TYPES.getSecondaryType);
  }

  updatepage(){
    this.page.emit();
  }
}
