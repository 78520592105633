<div [dir]="transDir.mainDirection" class="main-gallery-class center-vertically justify-align-top-vertical full-width-v full-height-v z-index-9999">
  <div class="center-horizontally space-between full-width">
    <div></div>
    <button (click)="closeGallery()" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div id="display-panel" class="center-vertically justify-align-center full-width full-height">
    <button (click)="leftSwitch()" [disabled]="selectedIndex === 0" *ngIf="images.length" mat-icon-button class="left-button">
      <mat-icon [ngClass]="selectedIndex === 0 ? 'disabled-color' : ''">keyboard_arrow_left</mat-icon>
    </button>
    <img #pinchableImage [src]="image" class="full-width auto-height"/>
    <button (click)="rightSwitch()" [disabled]="selectedIndex >= images.length - 1" *ngIf="images.length" mat-icon-button class="right-button">
      <mat-icon [ngClass]="selectedIndex >= images.length - 1 ? 'disabled-color' : ''">keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>
