import { Injectable } from '@angular/core';
import { ApiConstants } from '../../../constants';
import { NetworkService } from '../../networkService';

@Injectable({
  providedIn: 'root'
})
export class MeContactService {
  apis = new ApiConstants();

  constructor(private _networkService: NetworkService) {
  }

  contactUs(value: {name: string; phone: number; email: string; subject: string; text: string}) {
    const API_TYPES = this.apis.meContact();
    return this._networkService.post(API_TYPES.contactUs, value);
  }
}
