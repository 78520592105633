import { Injectable } from '@angular/core';
import { Direction } from '@angular/cdk/bidi';

@Injectable({
  providedIn: 'root'
})
export class TranslationAndDirectionService {
  private _mainDirection: Direction = 'ltr';

  constructor() { }

  get mainDirection(): Direction {
    return this._mainDirection;
  }

  set mainDirection(value: Direction) {
    this._mainDirection = value;
  }
}
