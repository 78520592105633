<mat-toolbar class="configurable-header toolbar-header"
             [class.drop-shadow]="title.length"
             [dir]="transDir.mainDirection">
  <div class="header_btn">
    <ng-content select="[firstAddition]" *ngIf="!canGoBack">
    </ng-content>
    <button *ngIf="canGoBack" mat-button class="header_btn" (click)="goBack()">
      <mat-icon *ngIf="transDir.mainDirection === 'rtl'">arrow_forward</mat-icon>
      <mat-icon *ngIf="transDir.mainDirection === 'ltr'">arrow_backward</mat-icon>
    </button>
  </div>
  <div class="spacer"></div>
  <div class="header-title">
    <h2>{{title}}</h2>
  </div>
  <div class="spacer"></div>
  <div class="header_btn">
    <ng-content select="[secondAddition]">
    </ng-content>
  </div>
</mat-toolbar>
