import { Component, Input, OnInit } from '@angular/core';
import { IPost, IUser } from '../../../core';
import { BasePage } from '../../classes';

@Component({
  selector: 'wi-mobi-post-item',
  templateUrl: './post-item.component.html',
  styleUrls: ['./post-item.component.scss']
})
export class PostItemComponent extends BasePage implements OnInit {
  @Input() postItem!: IPost;
  @Input() user!: IUser;
  starCount = 5;

  constructor() {
    super();
  }

  setImage(){
    return this.postItem.photo[0] ? this.postItem.photo[0] : 'assets/images/placeholder_post.jpg';
  }
  ngOnInit(): void {
  }
}
