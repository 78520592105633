import { Injectable } from '@angular/core';
import { ApiConstants } from '../../../constants';
import { NetworkService } from '../../networkService';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  apis = new ApiConstants();
  paymentApi = this.apis.publicPayment;

  constructor(private _networkService: NetworkService) {
  }

  get() {
    return this._networkService.get(this.paymentApi.get);
  }
}
