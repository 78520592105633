import { AfterViewChecked, Component } from '@angular/core';
import { Event, NavigationEnd } from '@angular/router';
import { BasePage } from '../../../../shared';
import { AuthService, MeMessagesService, MeProfileService } from '../../../../core';
import { OnInit } from '@angular/core/core';


@Component({
  selector: 'wi-mobi-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BasePage implements AfterViewChecked, OnInit {
  user_id = 0;
  canNotShowFooter = false;
  isAuth = false;
  canNotShowFooterDesk = false;
  year = new Date().getFullYear();
  messageCount = 0;
  private _footerRoutes = [
    '/',
    this.uiRoutes.dashboard.home,
    this.uiRoutes.products.myProducts,
    this.uiRoutes.chat.conversationsList,
    this.uiRoutes.dashboard.settingMenu
  ];

  private _footerRoutesDesk = [
    this.uiRoutes.auth.signInPhone,
    this.uiRoutes.auth.signUpInfo,
    this.uiRoutes.auth.signInCode,
  ];

  constructor(
    private _authService: AuthService,
    private _meMessageService: MeMessagesService,
    private _meProfileService: MeProfileService

  ) {
    super();
  }

  ngOnInit(): void {
    this.isAuth = !!this._authService.getSavedUser();
    const SAVED_USER = this._authService.getSavedUser();
    if (this.isAuth && SAVED_USER) {
      this._meProfileService.get().subscribe(res => {
        this.user_id = res.data.id;
        this.getMessageCount();
      });
      this.messageCount = SAVED_USER.msg_not_readed;

    }
  }

  getMessageCount() {
    this._meMessageService
      .getCount(this.user_id)
      .subscribe((res) => {
        if(res.success){
          this.messageCount = res.data.count;
          this.getMessageCount();
        }

      });
  }

  ngAfterViewChecked(): void {
    this.router
      .events
      .subscribe((value: Event) => {
        if (value instanceof NavigationEnd) {
          const IDX_Q = value.url.indexOf('?') >= 0 ? value.url.indexOf('?') : value.url.length;
          const TEST_URL = value.url.substr(0, IDX_Q);

          this.canNotShowFooter = !this._footerRoutes.includes(TEST_URL);
          this.canNotShowFooterDesk = this._footerRoutesDesk.includes(TEST_URL);
        }
      });
    this.isAuth = !!this._authService.getSavedUser();
  }

  changeLang(language: 'ar' | 'en') {
    localStorage.setItem('lang', language);
    window.location.reload(true);
  }
}
